:root {
  --main-gradient: linear-gradient(0deg, #2F3050, #6B6DB6);
  --light-gradient: linear-gradient(0deg, #8C0000, #F56C6C);
  --red-color: #8C0000;
}

@font-face {
  font-family: 'NeueAachenPro';
  src: url(/public/fonts/NeueAachenProMedium.TTF);
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body,
html {
  font-family: "Inter", sans-serif;
}

a {
  color: unset;
  text-decoration: none;
}

a:hover {
  color: inherit;
}
.lazyload-wrapper {
  display: unset;
}
.highlights marquee p {
  display: inline-block;
  text-transform: uppercase;
  margin: 0px 10px;
}

.highlights img {
  margin-left: 50px;
  float: left;
}

.highlights marquee p i {
  color: #000;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 105px;
}

.campuslogo {
  position: fixed;
  right: 10px;
  bottom: 74%;
  z-index: 9;
}

.highlights {
  background: var(--main-gradient);
  width: 100%;
  padding: 5px;
  position: relative;
  display: flex;
  overflow: hidden;
  padding-top: 40px !important;
}

.highlights:before {
  content: "";
  width: 80px;
  height: 100%;
  position: absolute;
  left: -15px;
  top: 0;
  background: linear-gradient(62deg, white 50%, transparent 50%);
}

.highlights p {
  color: #fff;
  margin-bottom: 0;
}

.logo {
  width: 30%;
  padding: 6px;
  padding-left: 80px;
}

.header-nav {
  width: 60%;
}

nav.navbar {
  background: url('https://webapi.entab.info/api/image/MGMB/public/Images/nav-bg.png');
  width: 96%; 
  float: left;
  margin-top: 28px;
  position: relative;
  z-index: 9;
  background-size: 100% 100%;
  background-position: center;
  padding: 0;
  padding-bottom: 9px;
  padding-top: 1px;
} 
nav.navbar ul.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 88%;
  margin: auto;
}

nav.navbar ul.navbar-nav li:hover {
  background: linear-gradient(45deg, #f56c6c, #f56c6c);
}

nav.navbar ul.navbar-nav li {
  text-transform: uppercase;
  padding: 15px;
  color: #fff;
  cursor: pointer;
}


.button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  width: 14%;
  border-radius: 6px;
  box-shadow: -2px 14px 11px -9px #0000008a;
  border: 0px; 
  min-width: 120px;
  cursor: pointer;
  transition: 0.5s;
  background: var(--main-gradient);
  color: #fff;
}

.button:hover {
  background: var(--light-gradient);
  box-shadow: -2px 14px 11px -6px #0000008a;
}

.newsbg {
  color: #fff;
  width: 76%;
  margin: auto;
  height: 94%;
}

.newsbg li {
  list-style: none;
  margin-bottom: 13px;
}

.newsbg ul.newsboxlist {
  padding: 0px;
}

.newsbg ul.newsboxlist .newsbox {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.newsbg .title {
  display: flex;
  align-items: center;
}

.newsbg button.button.light {
  position: absolute;
  right: 0;
  width: max-content;
  min-width: max-content;
  border-radius: 6px 0 0 6px;
}

.newsbg ul.newsboxlist .newsbox a {
  background: var(--main-gradient);
  color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.newsdesc {
  width: 90%;
}

.newsdesc h6 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.newsbg ul.newsboxlist .newsbox p {
  font-weight: 300;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.padding-0 {
  padding: 0;
}

.newsbg ul.newsboxlist li {
  background: #ffffff63;
  padding: 15px;
  border-radius: 15px;
}

.newsbg marquee {
  height: 600px;
}

.about-news {
  width: 100%;
  margin: auto; 
  background: url('https://webapi.entab.info/api/image/MGMB/public/Images/news-bg.jpg');
  background-size: 100% 100%;
  position: relative;
  padding: 50px 0;
  height: 100%;
}

.title {
  color: #000;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
}

.title p {
  margin-bottom: 0;
  color: #333333;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.title h2 {
  position: relative;
  width: max-content;
  max-width: 100%;
  font-size: 34px;
  line-height: 49px;
  margin: auto;
  font-weight: 700;
  color: #2F3050;
  letter-spacing: 2px;
  font-family: 'NeueAachenPro';
}

button.button {
  background: var(--red-color);
  width: max-content;
  margin: auto;
  min-width: 130px;
  text-transform: uppercase;
  margin-bottom: 3px;
  letter-spacing: 2px;
  font-weight: 300;
}

button.button.light {
  background: var(--light-gradient);
}

.homeeventbox {
  width: 90%;
  margin: auto;
  background: #fff;
  border-radius: 17px;
  overflow: hidden;
  border: 1px solid #E7E7E7;
  transition: 0.5s;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 8px;
}

.homeeventbox img {
  width: 100%;
  border-radius: 17px;
}

.homeeventboxbottom {
  position: absolute;
  top: 17px;
  right: 18px;
  background: var(--red-color);
  color: #fff;
  padding: 2px 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 0px #000;
}

.homeeventbox {
  position: relative;
  border: 2px solid #DADADA;
  background: #F5F5F5;
}

.homeeventbox:hover {
  margin-top: 0px;
}

.homeeventboxdesc {
  display: flex;
}

.homeeventboxdesc .homeeventboxbottom span {
  writing-mode: vertical-rl;
  transform: rotateZ(180deg);
  background: var(--light-gradient);
  padding: 30px 6px 22px;
  color: #fff;
}

.homeeventboxtitle p {
  margin: 0;
  font-size: 18px;
}

.homeeventboxtitle {
  width: 100%;
  padding: 0px 7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  text-align: center;
}

.homeeventboxtitle p.location {
  font-size: 13px;
  padding: 6px 10px;
  background: #2F3050;
  width: 100%;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  margin-top: 13px;
}

.eventbg button {
  margin-top: 30px;
}

.homeeventboxtitle p.location span {
  color: #fff;
  float: right;
}

.homeeventboxtitle span {
  display: inline-block;
  color: #999999;
  margin-top: 0;
}

.homeeventboxtitle span img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.homeeventbox img {
  width: 100%;
  height: 250px;
  border-radius: 17px;
}

.homeeventboxbottom {
  position: absolute;
  top: 17px;
  right: 18px;
  background: var(--red-color);
  color: #fff;
  padding: 2px 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 0px #000;
  text-transform: uppercase;
}

.homeeventbox {
  position: relative;
  border: 2px solid #DADADA;
  background: #F5F5F5;
}

p.title-evt {
  font-family: 'NeueAachenPro';
  font-weight: 400;
  font-size: 20px !important;
  text-align: center;
  width: 100%;
  margin: 10px 0
}

.homeeventboxtitle p {
  font-size: 17px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.eventbg {
  margin-top: 30px;
  padding-bottom: 30px;
}

.toper-block {
  display: flex;
}

.toper-block>div {
  width: 50%;
}

.event {
  background: url('https://webapi.entab.info/api/image/MGMB/public/Images/event-bg.png');
  background-size: 100% 100%;
}

.galleryimgbox {
  width: 90%;
  position: relative;
  margin: 5px auto;
  border-radius: 7px;
  overflow: hidden;
  border: 12px solid #fff;
  box-shadow: 0px 0px 8px #919191
}

.galleryimg:hover {
  transform: scale(1.1);
}

.galleryimg {
  margin: 40px 0;
  transition: 0.5s;
}

.galleryimgbox .gallerydesc {
  width: 100%;
  position: absolute;
  bottom: 0%;
  left: 0%;
  background: #2f3050bf;
  height: 38px;
  display: flex;
  align-items: center;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  transition: 0.5s;
}

.galleryimgbox .gallerydesc i {
  color: #fff;
  font-size: 40px;
  margin-bottom: 20px;
  display: none;
  transition: 2s;

}

.galleryimgbox:hover .gallerydesc i {
  opacity: 1
}

.galleryimgbox:hover i {
  display: block;
}

.galleryimgbox:hover .gallerydesc {
  height: 100%;
}

.galleryimgbox .gallerydesc h6 {
  font-size: 17px;
  margin-bottom: 0;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 30px;
  padding: 4px 10px;
  font-weight: 300;
}

.galleryimgbox:hover .gallerydesc {
  opacity: 1;
}

.homegallery {
  background-size: 100% auto;
  background-position: center;
  padding: 130px 0 0;
  background-repeat: no-repeat;
  background: url('https://webapi.entab.info/api/image/MGMB/public/Images/gallery-bg.png');
  background-size: 100% 100%;
  margin-top: -1px;
}

.four-blk img {
  width: 70px;
}

.highlights img {
  width: 20px;
}

.highlights marquee p a i {
  color: #fff;
}

.galleryimgbox img {
  width: 100%;
  height: 290px;
}

.ftr-logo ul {
  display: flex;
  list-style: none;
  padding: 0;
  align-items: center;
  justify-content: center;
}


.ftr-logo ul li {
  margin: 0px 5px;
}

.padded-sec {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.copyright {
  background: var(--main-gradient);
  text-align: center;
  font-size: 13px;
  padding: 5px 0;
  color: #fff;
}

.footer {
  padding-top: 80px;
  background: url('https://webapi.entab.info/api/image/MGMB/public/Images/footer-bg.gif');
  background-size: 100%;
  height: 100%;
  position: relative;
}

.footer:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, white 30%, #ffffffb5);
}

.footer>div {
  position: relative;
}

.arrrow-btn {
  position: absolute;
  right: 18%;
  top: 6%;
  background: var(--main-gradient);
  color: #fff;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  line-height: 0;
  justify-content: center;
  border-radius: 4px;
  box-shadow: -2px 11px 9px -10px #000;
}

.OnlineR {
  position: fixed;
  bottom: 20%;
  right: 10px;
  z-index: 9
}

.OnlineR img {
  width: 150px;
}

.aboutmidsec {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: -40px;
  z-index: 9;
  position: relative;
}

.aboutSection {
  background: url('https://webapi.entab.info/api/image/MGMB/public/Images/about-bg.png');
  background-size: 100% 100%;
  padding: 80px;
}

.abt-content.section-title {
  margin-bottom: 50px;
}

.aboutmidsec .button {
  padding: 18px 10px;
  margin: 10px;
  border-radius: 27px;
}

.aboutmidsec .button p {
  margin-bottom: 0;
}

.aboutmidsec .button.light {
  background: var(--light-gradient);
}

.newsbg .title h2 {
  color: #fff;
  font-weight: 400;
  font-size: 37px;
  text-transform: capitalize;
}

.newsbg .title {
  display: flex;
  align-items: center;
}

.newsbg button.button.light {
  position: absolute;
  right: 0;
  width: max-content;
  min-width: max-content;
  border-radius: 6px 0 0 6px;
}

.about-flex {
  width: 80%;
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  margin: 13px auto;
}

.about-flex img {
  width: 128px;
  margin-right: 20px;
}

.about-flex p {
  font-weight: 600;
  margin-bottom: 0;
}

.about-flex p span {
  color: var(--red-color);
}


.four-blk::before {
  content: "";
  width: 0%;
  height: 6px;
  position: absolute;
  transform: translate(-50%, -0%);
  left: 50%;
  top: 0%;
  transition: 0.5s;
  background: var(--red-color);
}

.four-blk:hover::before {
  width: 29%;
}


.four-blk p span {
  color: var(--red-color);
  display: block;
}

.four-blk {
  text-align: center;
  padding: 20px 15px;
  box-shadow: 0px 0px 15px 0px #ddd;
  height: 100%;
  border-radius: 5px;
  position: relative;
}

.four-blk h3 {
  font-size: 20px;
  margin: 10px 0;
  font-family: 'NeueAachenPro';
}

.four-block .button {
  margin-top: 40px;
}

.four-block {
  padding: 80px;
}

.toper-block {
  display: flex;
}

.toper-block>div {
  width: 50%;
}

.topperdesc p {
  margin-bottom: 0;
  font-size: 18px;
}

.topperdesc p span {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.topperdiv {
  text-align: center;
  margin-bottom: 30px;
}

.topperdiv img {
  margin: auto;
  width: 80%;
  margin-bottom: 10px;
  border-radius: 20px;
  height: 100%;
}

.toppertaj {
  height: 160px;
  margin-bottom: 15px;
}

.col-lg-4.birthday_section .item img {
  width: 65%;
  margin-top: 15px;
}

.message_content button {
  background: #fff;
  color: var(--red-color);
  border: 1px solid var(--red-color);
  box-shadow: unset;
  font-weight: 500;
  position: relative;
}

.message_content button:hover {
  color: #fff;
}

.topperdesc p.per {
  font-weight: 600;
  font-size: 20px;
  color: var(--red-color);
  font-family: 'NeueAachenPro';
  letter-spacing: 1px;
}

.toper-block>div h2 {
  font-size: 22px;
  text-align: center;
  margin: 30px 0;
  font-family: 'NeueAachenPro';
  font-weight: 600;
  letter-spacing: 2px;
}

.birthday_section .title {
  margin-bottom: 120px;
}

.top-ach {
  width: 80%;
}

.toper-birthday {
  background: url('https://webapi.entab.info/api/image/MGMB/public/Images/topper-bg.jpg');
  background-size: 100% 100%;
  padding-bottom: 100px;
}

.footer-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.footer-flex>div {
  width: 50%;
}


.contact_details>div {
  margin: auto;
  display: flex;
  align-items: center;
  background: #2f3050bf;
  margin-bottom: 10px;
  width: 70%;
  padding: 10px 20px;
  color: #fff;
  border-radius: 5px;
  min-height: 78px;
}

.contact_details>div i {
  color: var(--red-color);
  margin-right: 20px;
  font-size: 21px;
}

.footer-flex>div iframe {
  width: 80%;
  height: 370px;
  border: 10px solid #fff;
  box-shadow: 0px 0px 9px 2px #ddd;
}

.message_blk .d-flex {
  flex-wrap: wrap;
  display: flex;
}

.message_blk .d-flex>div {
  width: 31%;
  margin: 1%;
}

.message_blk .d-flex>div img {
  width: 100%;
}

.message_blk .d-flex .message_img {
  padding: 20px;
  position: relative;
  background: var(--main-gradient);
  border-radius: 9px;

}

.message_blk {
  width: 85%;
  margin: auto;
}

.message_blk .d-flex>div.message_content {
  padding: 40px;
  position: relative;
  text-align: center;
  box-shadow: 0px 0px 5px 0px #858585;
  border-radius: 9px;
  background: #fff;
}

.message_section {
  background: url('https://webapi.entab.info/api/image/MGMB/public/Images/message.png');
  background-size: 100% 100%;
  padding: 80px;
}

.message_content p {
  color: #666666;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.message_blk .d-flex>div.message_content:before {
  content: "";
  width: 90%;
  height: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #2F3050;
  border-radius: 9px;

}

.message_blk .d-flex>div.message_content h5 {
  color: var(--red-color);
  font-family: 'NeueAachenPro';
}

.message_blk .d-flex>div.message_content h6 {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #333333;
}

img.message-btn {
  position: absolute;

  width: 50px !important;
}

.message_img.chairman img.message-btn {
  top: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  left: 100%;
  z-index: 99;
}

.message_img.founder img.message-btn {
  top: 100%;
  transform: translate(-50%, -50%) rotate(0deg);
  left: 50%;
  z-index: 99;
}

.message_img.patron img.message-btn {
  top: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  left: 0;
  z-index: 99;
}

.popup {
  padding-right: 0 !important;
}

.popup .carousel-control-prev-icon {
  background: url('https://webapi.entab.info/api/image/MGMB/public/Images/lefticn.png') no-repeat !important;
}

.popup .carousel-control-next-icon {
  background: url('https://webapi.entab.info/api/image/MGMB/public/Images/righticn.png') no-repeat !important;
}

.popup .carousel-control-next,
.popup .carousel-control-prev {
  width: auto;
}

.popup .carousel-control-next {
  right: -15px;
}

.popup .modal-dialog {
  min-width: 40%;
  transform: translateY(-100px);
}

.carousel-item .modal-header {
  background: #0a4685;
  padding: 13px 0 0
}

.popup .modal-dialog img {
  width: 100%;
  object-fit: contain;
}

.carousel-item .modal-body h1 {
  padding-bottom: 0;
  text-align: center;
  font-size: 17px;
  color: #000;
  overflow: hidden;
  font-weight: 600;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  line-height: 28px;
}

.carousel-item .modal-dialog .modal-header .btn-close {
  color: #fff;
  padding-right: 31px
}

.carousel-item .modalcard .card-body {
  padding: 10px
}

.carousel-item .modalcard .card-body p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 35px;
  text-align: center;
  padding-top: 10px;
  line-height: 26px;
}

.carousel-item .modalcard {
  border: none
}

.popup .modal-content {
  padding: 0 0 10px
}

.carousel-item button.btn-close:focus {
  box-shadow: none
}

.carousel-item a.btn.btn-primary.btn-sm {
  border-radius: 6px;
  box-shadow: -2px 14px 11px -9px #0000008a;
  transition: .5s;
  background: #8c0000;
  border: #8c0000;
  margin: 0 10px;
  color: #fff !important;
  padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}

.carousel-item a.btn.btn-primary.btn-sm:focus {
  box-shadow: none;
  border: 1px solid #8c0000;
}

.carousel-item a.btn.btn-primary.btn-sm:hover {
  background: linear-gradient(0deg, #8c0000, #f56c6c);
}

.carousel-item button.btn-close {
  position: absolute;
  z-index: 999;
  background-color: #8c0000;
  right: 0;
}

.carousel-item .btn-close {
  background: url('https://webapi.entab.info/api/image/MGMB/public/Images/closeicn.png') no-repeat;
  opacity: 1;
  padding: 10px;
  background-position: center;
  border-radius: 0;
}

.modal-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.visitor-count {
  background: var(--main-gradient);
  color: #fff;
  padding: 5px 10px;
  border-radius: 7px;
  margin-left: 20px;
}

.header-nav ul.dropdown-menu a {
  background: transparent !important;
  color: #000 !important;
  padding: 0;
}

.header-nav ul.dropdown-menu {
  padding: 0;
}

.chatbot {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9;
  background: #fff;
  padding: 10px;
  border-radius: 50%;
}

.chatbot img {
  width: 75px;
}

.header-nav .nav-link {
  color: #fff;
}

.chatbot:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #e3e3e3;
  border-radius: 50%;
  transform: scale(1.1);
  animation: 2s scale infinite alternate;
}

.chatbot:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #e3e3e3;
  border-radius: 50%;
  transform: scale(1.1);
  animation: 2s scale1 infinite alternate;
}

.aboutmidsec .phone {
  background: #fff;
  display: flex;
  align-items: center;
  padding: 7px 8px;
  justify-content: center;
  box-shadow: inset 0px 0px 13px 0px #ddd;
  border-radius: 21px;
}

.aboutmidsec .phone img {
  width: 78px;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.4);
  }
}

@keyframes scale1 {
  0% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1.1);
  }
}

.infrastructure .galleryimgbox:hover .gallerydesc h6 {
  font-size: 24px;
  text-align: center;
}

.infrastructure {
  padding: 10px 0 70px;
}


.innerslide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(360deg, #1c1b3b8f 100%, #1c1b3b91 100%, #3333338c 100%), url('https://webapi.entab.info/api/image/MGMB/public/Images/innersld.jpg') no-repeat center center;
  background-size: cover;
  height: 255px;
  padding-bottom: 50px;
}

.innersec {
  min-height: 400px;
  padding: 60px 10px 80px 10px;
}

.innersec p {
  line-height: 34px;
  font-size: 16px;
  color: #4e4e4e;
}

.innersec h1 {
  z-index: -1;
  font-size: 35px;
  position: relative;
  padding-left: 70px;
  background: -webkit-linear-gradient(#474879, #a31717);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 45px;
  padding-bottom: 20px;
}

.innersec h1:after {
  position: absolute;
  content: ' ';
  width: 60px;
  height: 3px;
  background: -webkit-linear-gradient(#474879, #a31717);
  top: 20px;
  bottom: 0;
  left: 0;
}

.breadcrumb li:before {
  position: absolute;
  content: ' \F138 ';
  width: 100%;
  height: 100%;
  font-family: bootstrap-icons;
  left: -15px;
}

.breadcrumb li:first-child:before {
  content: "\f404";
  background: #ef3436;
  width: 30px;
  border-radius: 20px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -25px;
  top: -3px;
}

.breadcrumb li a {
  color: #f9f9f9;
  position: relative;
}

.breadcrumb li {
  padding: 0px 15px 0px 15px;
  color: #fff;
  position: relative;
  margin-left: 7px;
  font-size: 14px;
}

ul.breadcrumb {
  margin-bottom: 0;
}

.navbar-nav .dropdown-menu li {
  padding: 6px 10px !important;
  font-size: 15px;
  text-transform: capitalize !important;
  border-bottom: 1px solid #990d0d2e;
}

.left-img {
  border: 10px solid #fff;
  box-shadow: 1px 5px 8px 0px #ccc;
  border-radius: 4px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.mission-motto .box-shadow {
  padding: 34px 17px;
  height: 90%;
}

.box-pannel {
  display: flex;
  align-items: center;
}

.box-shadow {
  background: #fff;
  padding: 34px;
  border-radius: 3px;
  box-shadow: 0 0 14px 4px #dcdcdc;
  margin: 0 0 50px 0;
  /* height: 303px; */
}

.box-pannel img {
  min-width: 70px;
  min-height: 70px;
  margin-right: 23px;
}

ul.react-tabs__tab-list {
  display: flex;
  padding: 0px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 0;
}

.react-tabs__tab {
  list-style: none;
  outline: none;
  border: solid 1px #d8d8d8;
  width: max-content;
  padding: 5px 15px;
  margin-right: 25px;
  margin-bottom: 25px !important;
}

ul.react-tabs__tab-list li {
  list-style: none;
}

.react-tabs__tab--selected {
  border-bottom: 2px solid #f90067;
}

.innersec h4 {
  font-size: 20px;
  text-align: center;
  color: #2f3050;
  font-weight: 600;
  line-height: 33px;
}

.subhead {
  font-weight: bold;
  /* text-align: center; */
  color: #8c0000;
  font-size: 20px;
  margin-bottom: 8px;
  line-height: 35px;
}

.ullist {
  list-style: none;
  /* margin-left: 15px; */
}

.ullist li a {
  color: #1f1f1f;
  font-weight: 600;
  font-size: 17px;
  text-decoration: none;
}

.ullist li a:hover {
  color: #ef5716;
}

.ullist li {
  font-size: 17px;
  line-height: 44px;
  position: relative;
}

.ullist li::before {
  content: "";
  display: inline-block;
  width: 1em;
  background: url('https://webapi.entab.info/api/image/MGMB/public/Images/list.png');
  background-size: 100% 100%;
  color: #000;
  content: "";
  font-size: 20px;
  height: 20px;
  left: -5px;
  margin-left: -25px;
  margin-right: 12px;
  position: absolute;
  top: 6px;
  vertical-align: middle;
  width: 20px;
}

.checklist {
  list-style: none;
}

.checklist li {
  margin-bottom: 0px;
  font-weight: normal;
  font-family: 'Istok Web', sans-serif;
  color: black;
  line-height: 39px;
  font-size: 17px;
  text-align: justify;
}

.checklist li::before {
  content: "\2022";
  color: #de0806;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -20px;
  font-size: 25px;
}

.checklist li a span {
  color: red;
  font-size: 18px;
  font-weight: 600;
}

.checklist li a {
  color: black;
  text-decoration: none;
}

.student ul.ullist li a {
  text-decoration: none;
  background: #cabf00;
  margin-left: 3px;
  padding: 2px 5px;
  color: #fff;
  font-size: 93%;
  font-weight: normal;
}

.overview {
  padding: 0;
  background-image: url('https://webapi.entab.info/api/image/MGMB/public/Images/Overview-inner.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 600px;
  border-radius: 10px;
}

.overview .color-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(140 0 0 / 28%);
  border-radius: 10px 0px 0px 10px;
}

.overview p {
  color: #ffffff;
  text-align: center;
  line-height: 37px;
  padding: 18px;
}

.overview h1 {
  padding-top: 37px;
  margin-bottom: 0px;
}

.midhead {
  color: white;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 15px;
}

.staffbox {
  width: calc(19% - 18px);
  background: #fff;
  box-shadow: 0 0 10px 6px #00000017;
  margin: 20px 15px 20px 15px;
  border-radius: 2px;
  border-bottom: 5px solid #ccc;
}

h3.fac {
  font-size: 20px;
  line-height: 30px;
  position: relative;
}

h3.fac:before {
  position: absolute;
  content: ' ';
  background: #ccc;
  width: 99%;
  height: 3px;
  top: 15px;
  left: 0;
}

h3.fac span {
  background: #fff;
  position: relative;
  padding: 5px 10px;
}

.staffboxsec {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.imgbox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #efefef;
}

.imgbox {
  width: 100%;
  margin: 0 auto;
  height: 200px;
}
.imgbox> div{
  height: inherit;
}

.desc p {
  margin-bottom: 0;
  text-align: center;
  color: #000;
  font-size: 15px;
  line-height: 30px;
}

.desc p span:first-child {
  color: #ab0106;
  font-weight: bold;
  margin-bottom: 5px;
}

.desc p span {
  display: block;
  color: #000000a8;
  font-weight: normal;
  font-size: 14px;
  line-height: 29px;
}

.desc {
  padding: 10px 0px;
}

.mesdeskimg h3 {
  color: #d42300;
  margin-bottom: 8px;
  font-size: 18px;
  margin-top: 30px;
  line-height: 30px;
}

.tcblock {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.tc {
  width: 49%;
  margin-bottom: 30px;
}

.tcblock iframe {
  width: 49%;
}

.tc label {
  color: #000;
  width: 100%;
  margin-bottom: 0;
}

.tc label p {
  margin-bottom: 0px;
}

.tc .form-group {
  margin-bottom: 20px;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #ccc;
}

a.viewdetailsbtn {
  color: #fff;
  font-size: 14px;
}

.viewdetailsbtn {
  padding: 10px 15px;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  border: none;
  background: linear-gradient(131deg, #9c1010, #38395f);
  transition: all 0.2s ease-in;
}

.viewdetailsbtn:hover {
  background: linear-gradient(131deg, #40416d, #950909);
}

.verticaltabs .nav-pills .nav-link.active {
  background: -webkit-linear-gradient(#bfd875, #006e27);
  color: #fff;
}

.feeimg {
  border: 10px solid #333457;
  border-radius: 5px;
  box-shadow: 0px 0px 6px 4px #ccc;
}

.table-responsive {
  min-height: .01%;
  overflow-x: auto
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0
}

.table td,
.table th {
  background-color: #ffffff;
  border: 1px solid #bbb;
  color: #333;
  font-family: sans-serif;
  font-size: 100%;
  padding: 10px;
  vertical-align: middle;
  text-align: center;
}

.table tr:nth-child(even) td {
  background-color: #f0f0f0a1;
}

.table th {
  background-color: #444573 !important;
  color: #fff;
  font-size: 110%;
}

.table tr.even:hover td,
.table tr:hover td {
  color: #222;
  background-color: #fffcea;
}

.tg-bf {
  font-weight: 700
}

.tg-it {
  font-style: italic
}

.tg-left {
  text-align: left
}

.tg-right {
  text-align: right
}

.tg-center {
  text-align: center
}

.sample {
  padding: 60px 100px !important;
}

.sample-blk {
  display: inline-flex;
  flex-direction: row-reverse;
  padding: 10px 15px;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 100px;
  margin-bottom: 15px;
  width: 100%;
  box-shadow: 0px 2px 6px 1px #ccc;
  border-radius: 3px;
  border: 1px dashed #464777;
}

.sample-blk p {
  line-height: 30px;
  text-decoration: none !important;
}

.sample-blk img {
  max-width: 70px !important;
  max-height: 70px !important;
  margin-bottom: 0;
  margin-right: 35px;
}

.sample a {
  text-decoration: none;
}

.sample-blk:after {
  content: "";
  position: absolute;
  height: 100px;
  width: 175px;
  top: 17px;
  border-bottom-left-radius: 50%;
  right: -70px;
  background: linear-gradient(45deg, #f5f5ff, #ffffff);
  z-index: -1;
  transform: rotate(132deg);
}

.principalofficesec {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.row.fac-ssc {
  margin-bottom: 50px;
  margin-top: 30px;
}

.schoolfac .principaloffice {
  width: calc(20% - 15px);
}

.principaloffice {
  overflow: hidden;
  position: relative;
  width: calc(26% - 29px);
  margin: 0px 15px 30px 0px;
  background: #fff;
  box-shadow: 0 0 10px 6px #00000017;
}

.principalofficeimg {
  height: 160px;
  width: 80%;
  margin: 0 auto;
}

.principaloffice:hover .principalofficeimg img {
  transform: scale(1.1);
}

.principalofficelabel {
  position: absolute;
  top: -15px;
  background: #960a0a;
  width: 75%;
}

.principalofficelabel p {
  color: #fff;
  margin-bottom: 0px;
  padding: 5px;
  text-align: center !important;
}

.principalofficedesc {
  text-align: center !important;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 40px 10px 10px 10px;
  width: 100%;
}

.principaloffice p {
  text-align: center !important;
}

.principalofficedesc h5 {
  font-size: 20px;
  font-weight: 600;
  position: relative;
  padding-bottom: 6px;
  margin-bottom: 25px;
  color: #40426d;
}

.Bokaro p {
  line-height: 21px;
}

.principalofficedesc h5:after {
  content: ' ';
  position: absolute;
  top: 50%;
  width: 100px;
  border-bottom: 1px solid #790b12;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  bottom: -25px;
}

.principalofficedesc p span {
  display: block;
}
.toppertaj .lazyload-wrapper {
  height: inherit;
}
.principalofficeimg img {
  object-fit: contain;
  transition: all 0.2s ease-in;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  background: #f1f1f1;
}
.principalofficeimg > div{
  height: inherit;
  min-height: inherit;
  max-height: inherit;
}
img.img-fluid.mx-auto.d-block.cm {
  width: 50%;
}

.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #055a91;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}

.magnify-button-close:hover {
  background-color: #e00000
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

@-webkit-keyframes magnifyLoading {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

.facilities-img {
  width: 400px;
  position: relative;
}

.facilities-img:before {
  content: "";
  position: absolute;
  height: 270px;
  width: 400px;
  top: -30px;
  right: -30px;
  border: 15px solid #373861;
}

.facilities-img1 {
  width: 400px;
  position: relative;
}

.facilities-img1:before {
  content: "";
  position: absolute;
  height: 270px;
  width: 400px;
  top: -30px;
  left: -30px;
  border: 15px solid #33345f;
}

.facilities h3 {
  text-align: center;
}

.facilities p.first-letter:first-letter {
  font-weight: bold;
  font-size: 38px;
  color: #8c0000;
  line-height: 0;
}

.facilities img {
  box-shadow: 1px 1px 20px 0px #a7a7a7;
  position: relative;
}

.right-block {
  float: right !important;
  margin-right: 0px !important;
  margin-left: 20px;
}

.left-block {
  float: left;
  margin-right: 27px;
}

.clasroom img {
  box-shadow: 1px 1px 20px 0px #a7a7a7;
  margin-bottom: 20px;
}

.facilities hr {
  margin-top: 30px;
  border: 1px dashed #373861;
}

.smartclass {
  padding: 5px;
  margin-bottom: 20px;
  border: 5px double #ca40406b;
  border-radius: 5px;
}

.certi {
  background: #3a3b6230;
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 5px 1px #cccccc7d;
  border-radius: 4px;
}

.certi a {
  display: flex;
  justify-content: flex-start;
  text-decoration: none;
  align-items: center;
  flex-direction: row;
}

.certi a img {
  width: 85px;
  margin-right: 15px;
}

.certi a h4 {
  color: black;
  line-height: 30px;
  font-size: 16px;
  text-align: left;
  font-weight: normal;
}

.media {
  margin: 15px 0;
  border-left: 5px solid #53558e;
  box-shadow: 0 0 4px #0000004d;
  padding: 10px;
  background: #474ac724;
}

.counsel {
  padding: 20px;
  border-radius: 3px;
  background: linear-gradient(45deg, #fdf0bf, transparent);
}

.counsel p {
  color: #000;
  font-size: 21px;
  font-family: cursive;
  line-height: 35px;
  margin-bottom: 0;
}

#myImg,
#myImg1,
#myImg2 {
  width: 100%;
  border: 5px solid rgba(158, 158, 158, 0.1);
  background: #eaeaea;
  cursor: pointer;
  transition: 0.3s;
}

.erp_sec img {
  display: inline-block;
}

.erp_sec h4.imgheading {
  text-align: center;
}

.assistance {
  background: #eaeeff;
  padding: 20px;
  margin-top: 30px;
}

.erp_sec h4 {
  text-align: center;
  line-height: 40px;
  font-size: 17px;
  margin-bottom: 0;
  margin-top: 15px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}

.assistance {
  background: rgb(230 243 255);
  padding: 13px;
  border: solid 1px #ddd;
  margin-top: 20px;
}

.erpbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.erpboxdesc {
  width: 72%;
}

.erpboximg {
  width: 26%;
}

.erpboxdescpopup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.modal {
  background: #0000008a;
  z-index: 99999;
  padding-right: 0 !important;
}

.erppopup {
  width: 30%;
  margin: 10px;
}

.imgheading a {
  text-align: center;
  width: 100%;
  display: block;
  font-size: 20px;
  padding: 10px 0;
}

.erppopup .modal-body h5 {
  color: #000;
  text-align: center;
  padding-top: 20px;
}

.chooseyear {
  width: max-content;
  float: right;
  margin-bottom: 30px;
  margin-top: -45px;
}

#chooseddlYear option {
  color: black;
}

.chooseyear select {
  margin: 0px;
}

.chooseyear select {
  width: 100%;
  background: unset;
  border: unset;
  height: 28px;
  margin-top: 15px;
  border: unset;
  border-bottom: 1px solid grey;
}

.clr {
  clear: both
}

.video-gallery {
  padding: 20px;
  box-shadow: 0px 1px 4px 1px #03030330;
  margin-bottom: 20px;
  background: #e2edfc;
  border-radius: 3px;
}

.video-gallery a {
  display: flex;
  color: inherit;
  align-items: center;
}

.video-gallery a i {
  font-size: 40px;
  color: #ad0807;
  margin-right: 10px;
  display: flex;
}

.video-gallery a h3 {
  font-size: 16px;
  margin-bottom: 0;
  line-height: 24px;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 0;
  padding-left: 0;
}

.video-gallery p {
  font-size: 12px;
  margin-bottom: 0;
}

.video-gallery a h3:after {
  position: static;
}

.video-gallery a img {
  width: 52px;
  margin-right: 10px;
}

.row.tabs-dynamic {
  background: #cbdcf1;
  padding: 20px;
  margin: 0px 0px 40px 0px;
}

.row.tabs-dynamic p {
  margin-bottom: 0;
}

.row.tabs-dynamic p a {
  color: #585757;
}

.row.tabs-dynamic select {
  width: 100%;
  border: 0px;
  background: transparent;
  padding: 8px 5px;
  border-bottom: 1px solid #b6b6b6;
}

.row.tabs-dynamic input {
  padding: 9px 5px;
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #b6b6b6;
  background: transparent;
}

.count-val p {
  margin-bottom: 0px;
}

.magzine img {
  box-shadow: 0px 0px 4px 1px #ccc;
  border-radius: 3px;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.magzine h4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding: 0px 10px;
  font-size: 16px;
  line-height: 30px;
}

.gallery-inner {
  background: linear-gradient(88deg, #464776, #3f406a);
  margin-bottom: 30px;
  box-shadow: 7px 6px 12px -4px #ddd;
  /* position: relative; */
  overflow: hidden;
  transition: all 0.5s ease-in;
}

.gallery-blk a {
  color: inherit;
}

.gallery-content {
  padding: 20px 15px;
  position: relative;
  background: #fff;
  width: 96%;
  margin: -30px auto 10px auto;
}

.gallery-blk img {
  width: auto;
  max-width: 100%;
  height: 90%;
  margin: auto;
  display: block;
  position: relative;
  box-shadow: 0px 0px 19px 0px #00000054;
  border: 7px solid #ffffff4f;
}

.gallery-inner:hover .gallery-content {
  bottom: 0;
}

.gallery-blk {
  height: 230px;
  object-fit: contain;
  position: relative;
  background: #ddd;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub-gallerycount .gallery-blk img {
  height: 100%;
}

.sub-gallerycount .gallery-blk {
  height: 200px;
  margin-bottom: 20px;
}

.gallery-blk .gallery-title h3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.gallery-title h3 {
  font-size: 18px;
  color: #978a5a;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding: 0px;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
}

a.view-more {
  color: var(--black);
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

.gallery-title p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 28px !important;
  font-size: 16px !important;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.gallery-date {
  display: flex;
}

.gallery-date h6 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 25px;
  background: #b72c2c;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
}

.gall-num h5 {
  background: transparent;
  color: #000;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 25px;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
}

.gallery-title {
  font-size: 20px;
  line-height: 30px;
  font-family: 'Poppins', sans-serif;
}

.sub-gallerycount img {
  height: 200px;
  width: 100%;
  object-fit: cover;
  background: #f1f1;
  box-shadow: 0px 0px 3px 1px #0000004a;
}

.sub-gallerycount {
  margin-bottom: 20px;
}

.news-head {
  display: flex;
  align-items: center;
}

.news-head h2 {
  padding-right: 20px;
  border-right: 1px solid #c5c5c5;
  margin-right: 20px;
  background: #e5e5e5;
  padding: 4px 10px;
}

.news-head h3 {
  font-weight: 400;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 14px;
  color: #00599b;
  position: relative;
}

.news-blk {
  margin-bottom: 20px;
  padding-right: 30px;
  padding-bottom: 30px;
  width: 97%;
  box-shadow: 9px 11px 5px 0px #dddddd3d;
  position: relative;
  overflow: hidden;
  padding: 20px 0px 20px 20px;
  box-shadow: 0px 0px 12px 0px #95959591;
  /* border: 4px solid #b5b5b5; */
  transition: 0.5s;
}

.news-blk:before {
  content: "";
  position: absolute;
  top: -40px;
  right: -8px;
  width: 100px;
  height: 100px;
  background: #ecf9ff;
  border-radius: 50%;
}

.attachment {
  background: #494b7d;
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 5px;
}

.news-content p {
  margin-bottom: 6px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
  text-align: center;
}
.news-content p span {
  color: #e94242;
  text-transform: uppercase;
  cursor: pointer;
}
.modal.newpopup {}

.modal.newpopup .modal-dialog {
    width: 80%;
    max-width: 80%;
    border: 11px solid #fff;
    border-radius: 6px;
    overflow: hidden;
}

button.close {}

.modal.newpopup button.close {
    background: #db4949;
    border: 0px;
    color: #fff;
    padding: 0px 6px;
    font-size: 30px;
    border-radius: 5px;
    line-height: 32px;
}

.modal.newpopup .modal-dialog .modal-content {
    border: 0px;
    border-radius: 0;
}
.news-content p::-webkit-scrollbar {
  width: 0;
}

.news-blk:hover {
  box-shadow: rgb(0 0 0 / 5%) 0px 2px 1px, rgb(0 0 0 / 5%) 0px 4px 2px, rgb(0 0 0 / 5%) 0px 8px 4px, rgb(0 0 0 / 5%) 0px 16px 8px, rgb(0 0 0 / 5%) 0px 32px 16px;
}

.news-head h2 span {
  display: block;
  color: #153168;
  font-size: 18px;
  text-align: center;
}

.newsCount:nth-child(4n+1)>div {
  border-left: 3px solid #fff27e;
}

.newsCount:nth-child(4n+2)>div {
  border-left: 3px solid #ceb2ff;
}

.newsCount:nth-child(4n+3)>div {
  border-left: 3px solid #ff928f;
}

.newsCount:nth-child(4n+4)>div {
  border-left: 3px solid #ffb470;
}

.eventsnewsbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 2px 0px 8px 0px #ccc;
  margin-bottom: 20px;
}

.newsboximg {
  width: 40%;
  margin-right: 10px;
}

.newsboxdesc {
  width: 58%;
}

.newspgh {
  height: 150px;
  overflow-y: scroll;
  padding-top: 15px;
  margin-top: 10px;
  padding-right: 10px;
}

.uniformimg {
  height: auto;
  margin-bottom: 20px;
}

.newsboximg img {
  border: 10px solid #fff;
  height: 190px;
  object-fit: contain;
  background: #f1f1f1;
  width: 100%;
}

.newsdate {
  display: flex;
  justify-content: space-between;
  padding: 8px 5px;
}

.newspgh p {
  font-size: 15px;
  line-height: 30px;
}

.newsdate a {
  background: #464777;
  border-radius: 5px 3px 6px 0px;
  color: #fff;
  padding: 2px 5px;
}

.newsdate span {
  font-size: 14px;
  line-height: 30px;
}

.event-logo {
  height: 320px !important;
}

i {
  position: relative;
  top: -2px;
}

.newspgh h5 {
  font-family: 'Roboto', sans-serif;
  color: #000000ad;
  line-height: 30px;
  font-size: 18px;
}

.newspgh::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.newspgh::-webkit-scrollbar-thumb {
  background-color: var(--maroon);
}

.newspgh::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
}

.gallery-blks img {
  height: 230px;
  width: 100%;
  object-fit: cover;
}

.toppers-inner {
  padding: 10px;
  margin-bottom: 20px;
  background: #42437024;
  border-radius: 3px;
  box-shadow: inset 0px 0px 13px 5px #3d3e6857;
}

.toppers-inner img {
  margin: auto;
  width: 150px;
  height: 140px;
  border-radius: 3px;
  margin-top: 20px;
  display: block;
  border: 6px solid #fff;
}

.toppers-inner h3 {
  text-align: center;
  font-size: 15px;
  line-height: 25px;
  margin: 0px;
  margin-top: 10px;
}

.toppers-inner p {
  text-align: center;
  margin-bottom: 0;
}

.toppers-inner h4 {
  font-size: 15px;
  text-align: center;
}

.toppers-inner h5 {
  margin: 0px;
  color: #ac2121;
  font-size: 15px;
  text-align: center;
  line-height: 25px;
}

.activitybox {
  margin-bottom: 24px;
  background: #f1f1f1;
  padding: 10px;
  height: 300px;
  box-shadow: 0px 0px 6px 1px #00000030;
  border-radius: 3px;
}

.activitybox img {
  height: 210px;
  width: 100%;
}

.activitybox p {
  margin-bottom: 5px;
}

.activitybox h3 {
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-bottom: 0px;
  margin-top: 5px;
  line-height: 30px;
}

.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #055a91;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}

.magnify-button-close:hover {
  background-color: #e00000
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

@-webkit-keyframes magnifyLoading {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

.messagebox {
  border-radius: 4px;
  background: #fff;
  position: relative;
  width: 88%;
  padding: 0px 60px 35px 60px;
  margin-left: 180px;
}

.messageboximg img {
  width: 110px !important;
}

.principalimgbox {
  position: absolute;
  top: 0;
  width: 220px;
  left: -160px;
}

.homemsg {
  padding: 80px 0px;
  background: linear-gradient(0deg, rgba(255, 255, 255, .8), 10%, rgba(255, 255, 255, .8) 90%), url('https://webapi.entab.info/api/image/MGMB/public/Images/eventbg.jpg') no-repeat bottom right !important;
}

.messagetop {
  display: flex;
  justify-content: space-between;
}

.messageboximg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
  padding-left: 30px;
}

.messagedesc {
  padding: 0 30px 30px 30px;
}

.principalimgbox img {
  width: 100%;
  height: 240px;
  border: 2px solid #f3b516;
}

.messageboxtitle h3 {
  font-size: 30px;
  line-height: 35px;
  color: #fff;
  padding-left: 20px;
  margin-bottom: 0;
}

.messagedesc>div {
  color: #666666;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
  line-height: 30px;
  margin-bottom: 20px;
}

.messageboxtitle {
  background: #2f3050;
  border-radius: 0px 0px 40px 0px;
  display: flex;
  align-items: center;
  padding: 20px 60px 20px 30px;
  margin-top: 0;

  width: max-content;
  position: relative;
  margin-left: 0;
  text-transform: uppercase;
}

.messageboximg p span {
  color: #8c0000;
  font-weight: 400;
  display: block;
  font-size: 16px;
}

.messageboximg p {
  color: #29335D;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 0;
}

.curricbox {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.curriculumboximg {
  box-shadow: inset 0px 0px 10px 5px #ccc;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;
  border-radius: 50px;
  height: 100px;
}

.curriculumdesc p span {
  display: block;
  font-size: 13px;
  color: #6f71a9;
}

.curriculumboximg img {
  width: 50px;
}

.curriculumdesc {
  padding-top: 10px;
}

.ebookdiv:focus {
  outline: none;
}

.ebookdiv img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border: 10px solid #fff;
  object-position: top;
}

.ebookdiv {
  background: #48497a3d;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 3px #cccccc73;
}

.ebookdiv {
  background: #48497a3d;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 3px #cccccc73;
}

.curriculumbox {
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
  width: calc(18% - 30px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ebookslink a {
  background: #fff;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0px 0px 7px 1px #cccccc85;
  border-left: 4px solid #972800;
}

.ebookslink {
  margin-top: 30px;
}

.social-media {
  position: fixed;
  right: 0;
  z-index: 9;
  top: 50%;
  transform: translate(-0%, -50%);
  right: 0%;
}

.social-media ul {
  list-style: none;
  padding: 0;
}

.social-media ul li img {
  margin: 3px 0px;
  box-shadow: 0px 0px 5px 0px #000;
  border-radius: 50%;
  border: 1px solid #fff;
  background: #fff;
  transition: 0.5s;
  cursor: pointer;
}

.social-media li img:hover {
  box-shadow: 0px 0px 11px 0px #000;
}

.counselling .popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.counselling .popup-content {
  background: #fff;
  padding: 20px;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
}

.counselling .popup-content iframe {
  width: 100%;
  height: 80vh;
}

.counselling .popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #f00;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.counselling .popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: #0000001f;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counselling .popup-overlay iframe {
  background: #ddd;
  width: 1500px;
  max-width: 100%;
  margin: auto;
  display: block;
  height: 1100px;
  max-height: 80%;
}

.counselling .popup-overlay button.popup-close {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 30px;
  background: var(--main-gradient);
  color: #fff;
  padding: 1px 14px;
  border: 0px;
}

.news-content {
  padding-top: 15px;
  padding-right: 10px;
}

.news-content::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.news-content::-webkit-scrollbar-thumb {
  background-color: var(--maroon);
}

.news-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
}



.houses {
  box-shadow: 0px 0px 5px 0px #ddd;
  margin-bottom: 20px;
  height: 93%;
  border-radius: 6px;
  overflow: hidden;
}

.houses h3 {
  text-align: center;
  padding: 13px 3px;
  font-size: 23px;
  text-transform: uppercase;
}

.houses p {
  padding: 0 20px 20px;
}

.houses.green-house h3 {
  background: #2b7a2b;
  color: #fff;
  font-weight: 400;
}

.houses.blue-house h3 {
  color: #fff;
  background: #2323ad;
}

.houses.red-house h3 {
  background: #c52424;
  color: #fff;
}

.houses.yellow-house h3 {
  background: #ddd140;
}

.blinker {
  animation: 1s blinker infinite alternate;
}

@keyframes blinker {
  0% {
    color: #9ea1ff;
  }

  100% {
    color: #fffdb4;
  }
}

a.parantlogo-attachment {
  background: #fff;
  padding: 5px;
  border-radius: 50%;
  font-size: 20px;
  margin-left: -26px;
}
.newsdesc button {
  margin-top: 5px;
  padding: 4px 7px;
  border: 0;
  border-radius: 5px;
  font-size: 12px;
  text-transform: uppercase;
  background: var(--light-gradient);
  color: #fff;
}
.newsbg ul.newsboxlist .newsbox .newsdesc a {
  width: max-content;
  max-width: 100%;
  width: auto;
  height: auto;
  background: unset;
  display: block;
}
.news-blk .attachment{
  padding: 0;
}
.news-blk .attachment i.bi.bi-paperclip {
  margin: 4px;
}
.media-cover .magzine img{
height: 300px;
}
@media(max-width: 1600px) {
  .staffbox {
    width: calc(21% - 44px);
  }

  .header-nav {
    width: 67%;
  }

  .logo {
    padding-left: 40px;
  }

  .aboutmidsec .button {
    padding: 18px 5px;
    margin: 8px;
    border-radius: 27px;
    width: 15%;
  }

  .about-flex img {
    width: 100px;
    margin-right: 20px;
  }

  .padded-sec {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .message_blk .d-flex>div.message_content {
    padding: 30px;
  }

  .message_blk {
    width: 95%;
  }
}

@media(max-width: 1400px) {
  nav.navbar ul.navbar-nav li {
    padding: 15px 5px;
  }

  .newsbg marquee {
    height: 390px;
  }

  .staffbox {
    width: calc(25% - 30px);
  }

  .messagebox {
    width: 84%;
  }

  .highlights,
  nav.navbar {
    width: 100%;
  }

  .message_content p {
    -webkit-line-clamp: 6;
  }


}

@media(max-width: 1200px) {
  .innersec h1 {
    font-size: 30px;
  }

  .newsboxdesc {
    width: 100%;
    padding-left: 20px;
  }

  .newsboximg {
    width: 100%;
  }

  .newsbg marquee {
    height: 190px;
  }

  .curriculumbox {
    width: calc(23% - 30px);
  }

  .messagebox {
    width: 80%;
  }

  .facilities-img1:before,
  .facilities-img:before {
    display: none !important;
  }

  .staffbox {
    width: calc(26% - 40px);
  }

  .principaloffice {
    width: calc(34% - 25px);
  }

  .ftr-logo img {
    max-width: 100%;
  }

  .top-ach {
    width: 90%;
  }

  .highlights {
    padding-top: 0px !important;
  }

  .logo {
    width: 30%;
    padding: 6px;
    padding-left: 0;
  }

  .padded-sec {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .about-flex img {
    width: 85px;
  }

  .highlights:before {
    display: none;
  }

  .header .logo {
    width: 100%;
  }

  .header {
    flex-wrap: wrap;
    height: auto;
  }

  .header .logo img {
    margin: auto;
    display: block;
  }

  .header-nav {
    width: 100%;
  }

  .highlights {
    width: 100%;
  }

  nav.navbar {
    width: 100%;
    margin: 0;
  }


}

@media(max-width: 991px) {
  .curriculumbox {
    width: calc(29% - 30px);
  }

  .messageboxtitle {
    margin-bottom: 0;
  }

  .messagebox {
    width: 100%;
    margin-left: 0;
    padding: 0px 0px 35px 0px;
  }

  .principalimgbox {
    position: static;
    width: 300px;
    margin: 20px auto 20px auto;
  }

  .facilities-img1,
  .facilities-img {
    width: 100%;
    margin-bottom: 20px !important;

    margin: 0 auto;
  }

  .facilities img {
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
  }

  .staffbox {
    width: 44%;
  }

  .principaloffice {
    width: calc(50% - 18px);
  }

  .tc {
    width: 100%;
  }

  .tcblock iframe {
    width: 100%;
  }

  .col-lg-4.birthday_section .item img {
    width: 80%;
  }

  .about-flex {
    width: 100%;
  }

  .orderd-1 {
    order: 1 !important
  }

  .orderd-2 {
    order: 2 !important
  }

  .orderd-3 {
    order: 3 !important
  }

  .orderd-4 {
    order: 4 !important
  }

  .orderd-5 {
    order: 5 !important
  }

  .orderd-6 {
    order: 6 !important
  }

  .four-blk {
    height: 94%;
  }

  .contact_details>div {
    width: 90%;
  }

  .footer-flex>div iframe {
    width: 100%;
  }

  .about-flex {
    margin-bottom: 0;
    margin-top: 30px;
  }

  .aboutmidsec .button {
    width: 31%;
  }

  .aboutmidsec {
    flex-wrap: wrap;
  }

  .message_blk .d-flex>div {
    width: 48%;
  }

  .toper-block {
    margin-bottom: 20px;
  }

  .birthday_section .title {
    margin-bottom: 40px;
  }

  button.navbar-toggler {
    margin: 8px;
    float: right;
    display: block;
    margin-left: auto;
    background: var(--main-gradient);
    color: #fff;
  }

  .navbar-toggler-icon {
    color: #fff;
    filter: invert();
  }

  .top-ach {
    width: 100%;
  }

  nav.navbar {
    background: transparent;
    position: absolute;
    right: 0;
    top: 30px;
  }

  div#main_nav {
    background: #fff;
    text-align: left;
  }

  div#main_nav>ul {
    align-items: flex-start;
    padding: 15px;
    position: absolute;
    background: var(--main-gradient);
    border-radius: 15px;
    width: 100%;
  }

  .navbar-nav .dropdown-menu {
    position: static;
  }

  .header-nav .dropdown ul.dropdown-menu {
    position: static !important;
    background: #fff0 !important;
    color: #fff;
    border: unset;
  }

  div#main_nav ul li:last-child {
    border-bottom: 0px;
  }

  .header-nav .dropdown ul.dropdown-menu ul li a {
    color: #fff;
  }

  div#main_nav>ul>li {
    margin-bottom: 10px;
  }

  .header-nav ul.dropdown-menu a {
    color: #fff !important;
  }

  div#main_nav ul li {
    padding: 9px 8px;
    border-bottom: 1px dashed #ddd;
    width: 100%;
  }

  .message_img img.message-btn {
    top: 50% !important;
    transform: translate(-50%, -50%) rotate(-90deg) !important;
    left: 100% !important;
    z-index: 99;
  }
}

@media(max-width: 767px) {
  .modal.newpopup .modal-dialog {
    width: 95%;
    max-width: 100%;
    border: 11px solid #fff;
    border-radius: 6px;
    overflow: hidden;
    margin: auto;
}
  .curriculumbox {
    width: calc(39% - 30px);
  }

  .staffbox {
    width: 100%;
  }

  .principalofficeimg img {
    object-fit: contain;
  }

  .principaloffice {
    width: 100%;
  }

  .left-img {
    margin-left: 0;
    width: 100%;
  }

  .overview {
    height: 945px;
  }

  .aboutSection {
    padding-bottom: 20px;
  }

  .toper-block {
    flex-wrap: wrap;
  }

  .toper-block>div {
    width: 100%;
  }

  .padded-sec {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .toper-birthday {
    background-size: auto 100%;
    padding-bottom: 100px;
    background-position: center;
  }

  .footer-flex {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .footer-flex>div {
    width: 100%;
  }

  .contact_details>div {
    width: 100%;
  }

  .aboutmidsec .button {
    width: 46%;
  }
}

@media(max-width: 600px) {
  .message_img img.message-btn {
    top: 100% !important;
    transform: translate(-50%, -50%) rotate(0deg) !important;
    left: 50% !important;
    z-index: 99;
  }

  .aboutmidsec .button {
    width: 100%;
  }

  .header .logo img {
    width: 80%;
    padding-left: 20px;
    margin: unset;
    display: block;
  }

  .ftr-logo ul {
    justify-content: flex-start;
    margin-top: 20px;
  }

  .message_blk .d-flex>div {
    width: 100%;
  }

  .highlights img {
    margin-left: 20px;
    float: left;
  }
}

@media(max-width: 567px) {
  .curriculumbox {
    width: calc(49% - 30px);
  }
}